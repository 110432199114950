import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './components/style.css';
import './App.css';
import { GetTokenAPI } from './parameter';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'; //Legend
import Footer from './footer';
import Swal from "sweetalert2";
import Adfit from "./Adfit";
import styled from 'styled-components';
import GiftUrlCSV from './gift_url.csv';
import Papa from 'papaparse';
import { usePapaParse } from 'react-papaparse';
import TestRRSimg from './image/rrs-title2.png';
import TestNYRimg from './image/nyr-title.png';


const StyledButton = styled.button`
  display: inline-block;
  outline: none;
  border: none;
  border-radius: 30px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding-left: 3rem;
  padding-right: 3rem;
  /* 크기 */
  // height: 10rem;
  font-size: 1rem;
  /* 색상 */
  background: rgba(153,102,102,0.2);
  font-family: "misaeng";
`

function Button({ children, ...rest }) {
    return <StyledButton {...rest}>{children}</StyledButton>;
}

const IndiresultShare = () => {
    const token = window.location.href.split('/')[4];
    const [totalResult, setTotalResult] = useState({});
    const [indiResult, setIndiResult] = useState({});
    const [kingFirst, setKingFirst] = useState("");
    const [kingFirstScore, setkingFirstScore] = useState("");
    const [kingSecond, setKingSecond] = useState("");
    const [member, setMember] = useState("");
    const [orderRatio, setOrderRatio] = useState("");
    const [kingTotalRank, setKingTotalRank] = useState([]);
    const [keyword, setKeyword] = useState([]);
    const [usefulGift, setUsefulGift] = useState([]);  // 유용한 선물 리스트
    const [funnyGift, setFunnyGift] = useState([]);
    const [usefulGiftURL, setUsefulGiftURL] = useState([]);
    const [funnyGiftURL, setFunnyGiftURL] = useState([]);
    let kingGiftUrlTotal = {}; // 모든 왕에 대한 선물, URL 저장

    console.log("totalResult: ", totalResult);
    console.log(`indiResult: ${indiResult}`);
    const emoticon_first = process.env.PUBLIC_URL + '/image/emoticon_800_800/' + kingFirst + '.png';
    const emoticon_second = process.env.PUBLIC_URL + '/image/emoticon_800_800/' + kingSecond + '.png';
    let keywordSentence = ""
    for (let j = 0; j < keyword.length; j++) {
        keywordSentence += '"';
        let term = keyword[j.toString()]['0']
        keywordSentence += term;
        keywordSentence += '" ';
        let termNum = keyword[j.toString()]['1']
        keywordSentence += termNum;
        keywordSentence += "회 ";
    }
    keywordSentence += "언급하여";

    let king_total_rank_prep = [];
    for (let i = 0; i < kingTotalRank.length; i++) {
        const king_rank_data = {
            name: kingTotalRank[i.toString()]['0'],
            비율: kingTotalRank[i.toString()]['1']
        }
        king_total_rank_prep.push(king_rank_data);
    };
    const VisualizeKingTotalRank = (
        // 차트 위치 가운데 맞추기 | 경빈 | 22-07-05
        <ResponsiveContainer width='90%' aspect={5.0 / 3.0}>
            <BarChart
                data={king_total_rank_prep}
                layout="vertical"
                margin={{
                    left: 30,
                    bottom: 10,
                }}
                barSize={20}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 25]} />
                <YAxis type="category" dataKey="name" />
                <Tooltip />
                {/* <Legend /> */}
                <Bar dataKey="비율" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    )

    const containerStyle = {
        backgroundImage: "url(/image/background/basic_background.jpg)",
    }
    const imageCopyRight = () => {
        const imageCopyRightMsg =
            "저작권 이슈 발생 시 하기 이메일로 연락 주시길 바랍니다. \
              <br> \
              <br> 감사합니다. \
              <br> \
              <br> 📬 고객가치혁신팀 / cs.advancer@gmail.com"
        Swal.fire({
            icon: 'info',
            title: '상품 이미지 저작권',
            type: 'info',
            html: imageCopyRightMsg,
        });
    }

    const coupanglinkfunc0 = () => {
        console.log(`${usefulGift[0]}`);
        switch (`${usefulGift[0]}`) {
            case '앤디워홀 우는여인.jpg':
                return "https://link.coupang.com/a/tsiKM";
            case '갤럭시 필터렌즈.jpg':
                return "https://link.coupang.com/a/tsiKM";


            default:
                return "https://www.coupang.com/np/goldbox"; //기본페이지
        }
    }

    const coupanglinkfunc1 = () => {
        console.log(`${usefulGift[1]}`);
        switch (`${usefulGift[1]}`) {
            case '앤디워홀 우는여인.jpg':
                return "https://link.coupang.com/a/tsiKM";
            case '갤럭시 필터렌즈.jpg':
                return "https://link.coupang.com/a/tsiKM";


            default:
                return "https://www.coupang.com/np/goldbox"; //기본페이지
        }
    }

    const coupanglinkfunc2 = () => {
        console.log(`${funnyGift[0]}`);
        switch (`${funnyGift[0]}`) {
            case '앤디워홀 우는여인.jpg':
                return "https://link.coupang.com/a/tsiKM";
            case '갤럭시 필터렌즈.jpg':
                return "https://link.coupang.com/a/tsiKM";


            default:
                return "https://www.coupang.com/np/goldbox"; //기본페이지
        }
    }

    //king_total_rank
    const getResult = async () => {
        const params = { token: token };
        try {
            axios
                .get(GetTokenAPI, { params })
                .then(response => {
                    // console.log(`token: ${response.data}`);
                    const rankerNum = Number(response.data['ranker_num']);
                    const rankString = "rank_";
                    let ranker;
                    for (let i = 1; i <= rankerNum; i++) {
                        ranker = rankString + i.toString();
                        if (response.data[ranker]['token'] === token) break
                    }

                    setTotalResult(response.data);
                    setIndiResult(response.data[ranker]);
                    setKingFirst(response.data[ranker].king_first);
                    setkingFirstScore(response.data[ranker].score);
                    setKingSecond(response.data[ranker].king_second);
                    setMember(response.data[ranker].member);
                    setOrderRatio(response.data[ranker].order_ratio);
                    setKingTotalRank(response.data.king_total_num);
                    setKeyword(response.data[ranker].keyword);
                    setUsefulGift(response.data[ranker].gift_useful);
                    setFunnyGift(response.data[ranker].gift_funny);

                    // ReadGiftUrlCSV(response.data[ranker].king_first);

                });
        } catch (error) {
            console.log(error)
        }
    }

    // CSV 파일 처리 | 경빈 | 22-07-27
    const FetchCSV = () => {
        return fetch(GiftUrlCSV).then(function (response) {
            let reader = response.body.getReader();
            let decoder = new TextDecoder('utf-8');
            return reader.read().then(function (result) {
                return decoder.decode(result.value);
            });
        });
    }

    // 선물별 링크 가져오기 | 경빈 | 22-07-27
    const getGiftURL = () => {
        const usefulGiftURLlist = [];
        const funnyGiftURLlist = [];
        console.log(`kingGiftUrlTotal: ${kingGiftUrlTotal}`);
        console.log(kingGiftUrlTotal[kingFirst]);
        console.log(kingGiftUrlTotal['게임왕']['실용']['0']['gift_name']);
        console.log(`kingFirst: ${kingFirst}`);
        kingGiftUrlTotal[kingFirst]["실용"].map((data) => {
            if (usefulGiftURLlist.length < 2) {
                if (data.gift_name === (usefulGift[0] || '').split('.')[0] || data.gift_name === (usefulGift[1] || '').split('.')[0]) {
                    usefulGiftURLlist.push(data.gift_url);
                }
            }
        });
        kingGiftUrlTotal[kingFirst]["재미"].map((data) => {
            if (data.gift_name === (funnyGift[0] || '').split('.')[0]) {
                funnyGiftURLlist.push(data.gift_url);
            }
        });
        // console.log(`usefulGiftURL: ${usefulGiftURL[0]}`);
        // console.log(`usefulGiftURL: ${usefulGiftURL[1]}`);
        // console.log(`funnyGiftURL: ${funnyGiftURL[0]}`);
        setUsefulGiftURL(usefulGiftURLlist);
        setFunnyGiftURL(funnyGiftURLlist);
    };

    // 쿠팡 선물-URL CSV 파일 읽기 | 경빈 | 22-07-27
    const ReadGiftUrlCSV = async () => {
        console.log(`ReadGiftUrlCSV kingFirst: ${kingFirst}`);
        let csvData = await FetchCSV();
        let results = Papa.parse(csvData, { header: true });
        let kingGiftUrlObj = {}; // 왕 1명에 대한 선물, URL 저장
        let usefulGiftList = []; // 실용 선물 리스트 저장
        let funnyGiftList = []; // 재미 선물 리스트 저장
        let kingLast = "게임왕";
        results.data.map((rows) => {
            const kingName = rows.king_name;
            const giftType = rows.gift_type;
            const giftName = rows.gift_name;
            const giftURL = rows.url;

            // 새로운 왕 탐색하는 경우
            if (kingName != kingLast) {
                kingGiftUrlObj["실용"] = usefulGiftList;
                kingGiftUrlObj["재미"] = funnyGiftList;
                usefulGiftList = funnyGiftList = []; // 객체 초기화
                kingGiftUrlTotal[kingLast] = kingGiftUrlObj;
                kingGiftUrlObj = {} // 객체 초기화
                kingLast = kingName // 새로 탐색하는 왕
            }

            let params = {
                "gift_name": giftName,
                "gift_url": giftURL,
            }
            // 실용-재미 선물 구분
            giftType === '실용' ? usefulGiftList.push(params) : funnyGiftList.push(params);

        });
        console.log(`kingGiftUrlTotal: ${kingGiftUrlTotal['게임왕']}`);
        getGiftURL();
    };

    // 선물 출력 화면 모듈화 | 경빈 | 22-07-28
    const visualizeGiftList = () => {
        if (kingFirst)
            return (
                <div>
                    <h3><span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{kingFirst}</span> 이 좋아할 만한 선물은?</h3>
                    {/* <h3><u>{kingFirst}</u>이 좋아할 만한 선물은?</h3> */}
                    <div className='gift-parent'>
                        <div className='gift-child' >
                            <a href={usefulGiftURL[0]} target="_blank">
                                <img src={`/image/coupang_gift/${kingFirst}/실용/${usefulGift[0]}`} className="gift-child-img" alt='present1'></img>
                            </a>
                            {/* <img className='gift-child-img' src={`/image/gift/${kingFirst}/실용/${usefulGift[0]}`} alt="present1" /><br /> */}
                            <h4 className='light'>{(usefulGift[0] || '').split('.')[0]}</h4>
                            <a href={usefulGiftURL[0]} target="_blank">
                                <img src={`${process.env.PUBLIC_URL}/image/button/선물하기.png`} className="img_5" alt='present1'></img>
                            </a>
                        </div>

                        <div className='gift-child'>
                            <a href={usefulGiftURL[1]} target="_blank">
                                <img src={`/image/coupang_gift/${kingFirst}/실용/${usefulGift[1]}`} className="gift-child-img" alt='present2'></img>
                            </a>
                            {/* <img className='gift-child-img' src={`/image/gift/${kingFirst}/실용/${usefulGift[1]}`} alt="present2" /><br /> */}
                            <h4 className='light'>{(usefulGift[1] || '').split('.')[0]}</h4>
                            <a href={usefulGiftURL[1]} target="_blank">
                                <img src={`${process.env.PUBLIC_URL}/image/button/선물하기.png`} className="img_5" alt='present2'></img>
                            </a>
                        </div>

                        <div className='gift-child'>
                            <a href={funnyGiftURL[0]} target="_blank">
                                <img src={`/image/coupang_gift/${kingFirst}/재미/${funnyGift[0]}`} className="gift-child-img" alt='present3'></img>
                            </a>
                            {/* <img className='gift-child-img' src={`/image/gift/${kingFirst}/재미/${funnyGift[0]}`} alt="present3" /><br /> */}
                            <h4 className='light'>{(funnyGift[0] || '').split('.')[0]}</h4>
                            <a href={funnyGiftURL[0]} target="_blank">
                                <img src={`${process.env.PUBLIC_URL}/image/button/선물하기.png`} className="img_5" alt='present3'></img>
                            </a>
                        </div>
                    </div>
                </div>
            );
        else
            return (
                <div>
                    <h3>선물 DB 로딩 중</h3>
                </div>
            )
    }

    // DB 조회 후 선물CSV parsing | 경빈 | 22-07-28
    useEffect(() => {
        if (!kingFirst) getResult();
        else ReadGiftUrlCSV();
    }, [kingFirst])



    return (
        <div className='stretch_container' style={containerStyle}>
            <Adfit height={50} width={320} unit={"hTnetVlIpgUtPXFG"} scrFlag={true} position={"middle"}></Adfit>
            <br />
            <div className="adfit"></div>
            <div className="capture">
                {/* <h1>임명장</h1> */}
                <br /><br />
                <img src={`${process.env.PUBLIC_URL}/image/background/임명장2.png`} className='img_2' alt='img'></img>
                <br />
                <img style={{ width: '45%' }} src={emoticon_first} alt="king_photo" /><br />
                <Button>
                    <h2><span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{member}</span> 님은</h2>
                    {/* <h2><u>{member}</u> 님은</h2> */}
                    <h3 className='light'>{keywordSentence}</h3>
                    {/* <u>{keywordSentence}</u> */}
                    <h3 className='light'>대화방 내 <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{kingFirst}</span> 으로 선포합니다!</h3>
                    {/* 대화방 내 <u>{kingFirst}</u>을 담당하고 있음을 선포합니다! */}
                </Button>
                <br /><br />
                <h5 className='dashLine'></h5>
                {/* 선물 이미지 크기 조정 | 경빈 | 22-07-04 */}
                {visualizeGiftList()}
                <br />
                {/* <span style={{ float: 'right', fontSize: 'small', alignContent: 'right', textAlign: 'right', color: '#000000', marginRight: '20px', fontWeight: 'bold' }}>이미지 클릭 시 선물하기 페이지로 이동합니다.</span> */}
                <br />
                <span style={{ float: 'right', fontSize: 'small', alignContent: 'right', textAlign: 'right', color: '#0066ff', marginRight: '20px' }} onClick={() => imageCopyRight()}><u>*이미지 저작권 공지</u></span>
                <h5 className='dashLine'></h5>
                <div className='gift-parent'>
                    <div className='gift-child'>
                        <h3>당신이 아쉽게 놓친 왕은?</h3>
                        {/* 이미지 크기 늘리기 | 경빈 | 22-07-04 */}
                        <img style={{ width: '50%' }} src={emoticon_second} alt="king_photo" /><br />
                        <h4 className='light'><span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{kingSecond}</span> 입니다!</h4>
                        {/* <h4 className='light'><u>{kingSecond}</u>입니다!</h4> */}
                    </div>
                    <div className='gift-child'>
                        <h3>당신의 <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{kingFirst}</span> 역량은?</h3>
                        {/* <h3>당신의 <u>{kingFirst}</u> 역량은?</h3> */}

                        {/* 글자수 안맞아서 전세계 일단 밑으로 빼둠 | 윤수 | 22-07-03*/}
                        {/* 텍스트 수직 방향 가운데로 정렬 | 경빈 | 22-07-04 */}
                        {/* 맞춤법 교정 | 경빈 | 22-07-05 */}

                        <h4 style={{ color: "red", fontSize: 18, marginTop: 90 }}>전 세계 상위 <span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{orderRatio}</span>%</h4>
                        {/* <h4 style={{ color: "red", fontSize: 18, marginTop: 90 }}>전 세계 상위 <u>{orderRatio}%</u></h4> */}
                        <h4 className='light'>(역량점수: <span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{kingFirstScore}</span> 점)</h4>
                        {/* <h4 className='light'>(역량점수: <u>{kingFirstScore}점</u>)</h4> 왕 키워드 사용횟수 총합 */}
                    </div>
                    <br />
                </div>
                <h5 className='dashLine'></h5>
                <div>
                    <h3>전 세계 가장 많은 왕 순위는?</h3>
                    {VisualizeKingTotalRank}
                </div>
            </div>

            <Link to="/">
                <img className='img_3'
                    src={`${process.env.PUBLIC_URL}/image/button/다시하기.png`}
                    alt="mainPageBtn"
                />
            </Link>

            <Link to="/qanda">
                <img className='img_3'
                    src={`${process.env.PUBLIC_URL}/image/button/자주묻는질문.png`}
                    alt="qAndaBtn"
                />
            </Link>
            <div>
                <br />
                <span>👇 다른 테스트 해보기 👇</span><br />
                <br />
                <img src={TestRRSimg} width={230} height={60} onClick={() => { window.open('https://advancer-rrs.click/'); }} /><br />
                <img src={TestNYRimg} width={230} height={60} onClick={() => { window.open('https://www.newyearrabbit.click/'); }} />
            </div>
            <br />
            <h5 className='light'>이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다.</h5>
            {/* <h5 className='light'>파일은 1회성 분석 외에 사용되지 않고 삭제됩니다.</h5> */}
            <Footer></Footer>
            <br />
            <Adfit height={100} width={320} unit={"a3z80YxaOerWU9V9"} scrFlag={false} position={"bottom"}></Adfit>
        </div>
    );
}

export default IndiresultShare;