import { Link, useNavigate } from 'react-router-dom';
import React, { Component } from 'react';
import axios from 'axios';
import { UploadFileAPI, GetUserIdAPI } from './parameter';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './components/style.css';
import Swal from "sweetalert2"; // alert 디자인 템플릿 활용 | 경빈 | 22-07-05 // 
// import { Accordion, AccordionItem } from 'react-light-accordion';
import Footer from './footer';
import Adfit from "./Adfit";

let giftList = {}; // 왕별 선물 이미지 파일 리스트
let giftSet = {}; // 왕별 선물 랜덤 세트

// 왕별 선물 이미지 리스트 출력 | 경빈 | 22-07-18
const getGiftList = () => {
  const getFiles = (r) => {
    let files = {};
    r.keys().map((item, index) => {
      files[item.replace("./", "")] = r(item);
    });
    return files;
  }

  const GiftImgFiles = getFiles(require.context("../public/image/coupang_gift/"));

  let fileTotalList = Object.keys(GiftImgFiles);
  // let testjson = {};
  let kingLast;
  let getFileFirstFlag = true; // 맨 첫 파일검증 여부
  let usefulGiftList = [];
  let funnyGiftList = [];
  console.log("kingLast: ", kingLast);
  fileTotalList.map((file) => {
    let fileInfo = file.split('/');
    let kingName, giftType, fileName;
    kingName = fileInfo[0];
    giftType = fileInfo[1]; // '실용', '재미' 구분
    fileName = fileInfo[2];

    // 첫 번째 왕 검증할 시 직전 분석 왕 없으므로 따로 설정
    if (getFileFirstFlag) {
      kingLast = kingName;
      getFileFirstFlag = false;
    };

    // console.log(`kingName: ${kingName}, kingLast: ${kingLast}, fileName: ${fileName}`);

    // 새로운 왕에 대한 파일인 경우
    if (kingName != kingLast) {
      giftList[kingLast] = {
        '실용': usefulGiftList,
        '재미': funnyGiftList
      };
      usefulGiftList = [];
      funnyGiftList = [];
      kingLast = kingName;
    }

    if (giftType === '실용') usefulGiftList.push(fileName);
    else funnyGiftList.push(fileName);
  })
}

// 선물 조합 만들기 | 경빈 | 22-07-18
const getCombinations = (array, selectNumber) => {
  const results = [];
  if (selectNumber === 1) return array.map((element) => [element]);
  array.forEach((fixed, index, origin) => {
    const rest = origin.slice(index + 1);
    const combinations = getCombinations(rest, selectNumber - 1);
    const attached = combinations.map((combination) => [fixed, ...combination]);
    results.push(...attached);
  });
  return results;
}


const TIMEOUT = 10000;

const Container = styled.div`
  margin-left : 10%;
  margin-right : 10%;
  overflow:hidden;
`;

const StyledSlider = styled(Slider)`
  .slick-dots{
    bottom:-50px;
    margin-top:200px;
  }
  .slick-slide div{
    outline: none;
  }
`;

const ImageContainer = styled.div`
  margin: 0 0px;
`;

const Image = styled.img`
min-width:100%;
min-height:100%;
max-width:100%;
max-height:100%;
`;

const img1 = require('./image/app1.jpg');
const img2 = require('./image/app2.jpg');
const img3 = require('./image/app3.jpg');
const img4 = require('./image/app4.jpg');
const img5 = require('./image/app5.jpg');
const img6 = require('./image/app6.jpg');
const img7 = require('./image/app7.jpg');
const img8 = require('./image/app8.jpg');
const img9 = require('./image/app9.jpg');

const web1 = require('./image/web1.jpg');
const web2 = require('./image/web2.jpg');
const web3 = require('./image/web3.jpg');
const web4 = require('./image/web4.jpg');
const web5 = require('./image/web5.jpg');
const web6 = require('./image/web6.jpg');
const web7 = require('./image/web7.jpg');

const items = [
  { id: 0, url: img1 },
  { id: 1, url: img2 },
  { id: 2, url: img3 },
  { id: 3, url: img4 },
  { id: 4, url: img5 },
  { id: 5, url: img6 },
  { id: 6, url: img7 },
  { id: 7, url: img8 },
  { id: 8, url: img9 },
];

const webitems = [
  { id: 0, url: web1 },
  { id: 1, url: web2 },
  { id: 2, url: web3 },
  { id: 3, url: web4 },
  { id: 4, url: web5 },
  { id: 5, url: web6 },
  { id: 5, url: web7 },
];

const FileUploadStyle = styled.div`
  margin: 0 8px 0 8px;
  img {
    max-width: 150px;
  }
  label {
    display: inline-block;
    font-size: inherit;
    vertical-align: middle;
    cursor: pointer;
  }
  input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;

// Mac PC 카톡 파일 분석 로직 | 경빈 | 22-07-13
const DELIMITER = ',';
const APOSTROPHE = '"';

const containerStyle = {
  backgroundImage: "url(/image/background/basic_background.jpg)",
}

//React 에서 제공하는 Component class를 상속받아 구현
class Uploadfile extends Component {
  //--------기본 생성자 format | 경빈 | 22-06-29--------//
  constructor(props) {
    super(props);
    this.state = {
      userID: 1,
      selectedFile: null,
      fileUploadedSuccessfully: false,
      fileUploadBtnDisabled: true, // 파일 확인용
      getData: false,
      result: null,
      GiftSet: {},
    };
  }

  errorCatch = err => {
    console.error(err);
    return (
      <div>
        <Link to="/"></Link>
      </div>
    )
  }

  //---------------------------------------------//
  onFileChange = event => {
    try {
      this.setState({ selectedFile: event.target.files[0] });
      //-----최신 userID 조회하고 1 증가 | 경빈 | 22-06-29------//
      axios
        .get(GetUserIdAPI, { timeout: TIMEOUT })
        .then(response => {
          console.log("response.data: ", response.data);
          getGiftList();
          // console.log(`giftList: ${giftList}`);
          Object.keys(giftList).map((item) => {
            let giftTotal = giftList[item];
            let giftUseful = giftTotal['실용'];
            let giftFunny = giftTotal['재미'];
            // console.log(`giftTotal['실용']: ${giftTotal['실용'].length}`);
            let giftUsefulSet = getCombinations(giftUseful, 2);
            // console.log(`length: ${giftUsefulSet.length}`);
            let randomNumberUseful = parseInt(Math.random() * giftUsefulSet.length);
            // console.log(`randomNumberUseful: ${randomNumberUseful}`);
            let randomNumberFunny = parseInt(Math.random() * giftFunny.length);
            // console.log(`randomNumberFunny: ${randomNumberFunny}`);
            let giftFunnySet = getCombinations(giftFunny, 1);
            giftSet[item] = { '실용': giftUsefulSet[randomNumberUseful], '재미': giftFunnySet[randomNumberFunny] };
          })
          // console.log(`giftSet: ${giftSet}`);
          // console.log(`giftSet: ${giftSet['게임왕']['유용']}`);
          this.setState({
            userID: Number(response.data) + 1,
            GiftSet: giftSet,

          },
            () => { console.log("userID: ", this.state.userID); })
        });
      //--------------------------------------------------//
    } catch (err) {
      this.errorCatch(err);
    }
  }

  // 파일선택 후 업로드 버튼 누를 수 있도록 구현 | 경빈 | 22-07-04
  onFileUpload = (uploadCheck) => {
    try {
      if (uploadCheck === true) {
        //-- 1 증가시킨 userID back으로 전달 | 경빈 | 22-06-29 --//
        const formData = new FormData();
        // console.log("this.state.GiftSet: ", JSON.stringify(this.state.GiftSet));
        formData.append(JSON.stringify(this.state.GiftSet), this.state.selectedFile, this.state.userID);

        //--------------------------------------------------//

        axios.post(UploadFileAPI, formData) //서버에 데이터를 보내는 POST 메서드
          //.then(() => { //무조건동작
          //})
          .then(() => { //response 빼서 무조건동작
            this.setState({
              selectedFile: null,
              fileUploadBtnDisabled: true,
              fileUploadedSuccessfully: true,
            });
            //분석중 페이지로 넘어가기
            this.props.navigate('/analysis', { state: { userID: this.state.userID } });
          })
          .catch((error) => { this.props.navigate('/uploadfile') })
      } else {
        // alert 디자인 템플릿 활용 | 경빈 | 22-07-05 // 
        Swal.fire({
          icon: 'info',
          title: '파일 미선택',
          type: 'info',
          text: '파일을 먼저 업로드해 주세요 :)',
          confirmButtonText: '알겠습니다',
        });
      }
    } catch (err) { this.errorCatch(err); }
  }

  // 유효한 파일의 경우 | 경빈 | 22-07-13
  validFile = () => {
    try {
      console.log("유효한 파일");
      this.setState({
        fileUploadBtnDisabled: false,
      })
      // alert 디자인 템플릿 활용 | 경빈 | 22-07-05 // 
      Swal.fire({
        icon: "success",
        title: '유효한 카톡 파일 확인',
        type: "success",
        html: '분석 시작하기 버튼 클릭 시 \
              <br> \
              <br> \
              광고 페이지 이동 후 분석이 시작됩니다 :)',
        showCancelButton: true,
        confirmButtonText: '분석 시작하기',
        cancelButtonText: '취소하기',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

      }).then((res) => {
        if (res.isConfirmed) {
          this.onFileUpload(!this.state.fileUploadBtnDisabled);
        } else {
          Swal.fire({
            icon: 'error',
            title: '분석을 취소하였습니다.',
            text: '파일 선택 후 "분석 시작하기" 버튼을 클릭해 주세요 :)',
            confirmButtonText: '알겠습니다',
          }).then((res) => {
            this.setState({
              selectedFile: null,
              fileUploadBtnDisabled: true,
            });
          })
        }
      });
    } catch (err) { this.errorCatch(err); }
  }

  // 유효하지 않는 파일의 경우 | 경빈 | 22-07-13
  invalidFile = () => {
    // alert 디자인 템플릿 활용 | 경빈 | 22-07-05 // 
    Swal.fire({
      icon: 'error',
      title: '유효하지 않은 파일 유형',
      text: '내보내기 한 카톡 파일만 선택해 주세요.',
      footer: '카톡 내보내기 방법은 아래 사진을 참고해 주세요 :)',
      confirmButtonText: '알겠습니다',
    });
    this.setState({
      fileUploadBtnDisabled: true,
      selectedFile: null
    })
  }

  //---txt 기반의 카톡 파일만 업로드 허용하기 | 경빈 | 22-06-29---//
  fileDataCheck = (fileContent) => {
    try {


      //---Mac PC 카톡 파일 분석 로직 | 경빈 | 22-07-13---//
      if (this.state.selectedFile.type == 'text/csv') {
        let content = fileContent.split(/\r\n|\n/);
        let column_name = content[0];
        // 카톡 파일인 경우의 칼럼명과 일치하는 경우: 유효한 파일 처리
        if (column_name == "Date,User,Message") this.validFile();
        // 카톡 파일과 다른 칼럼명인 경우: 유요하지 않는 파일 처리
        else this.invalidFile();
      }
      else if (fileContent.includes('카카오톡 대화') && this.state.selectedFile.type === 'text/plain') {
        this.validFile();
      } else this.invalidFile();
    } catch (err) {
      this.errorCatch(err);
    }
  }
  //----------------------------------------------------//

  fileData = () => {
    try {

      if (this.state.selectedFile && this.state.fileUploadBtnDisabled) {
        console.log(this.state.selectedFile);
        //---업로드한 파일 내용 읽기 | 경빈 | 22-06-29---//
        let fileReader = new FileReader();
        fileReader.onload = () => { this.fileDataCheck(fileReader.result) }
        fileReader.readAsText(this.state.selectedFile);
      }
    } catch (err) {
      this.errorCatch(err);
    }
  }

  // Uploadfile Component가 View에 어떤 식으로 보일지 정의한다.
  // 아래의 input type 이 파일선택 창을 만든다.
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      //variableWidth: true,
      slidesToScroll: 1,
      arrows: true,
      centerMode: true,
      //centerPadding:'0px',

    };

    return (
      <div className='stretch_container' style={containerStyle}>
        <br />
        <h1>카톡 대화방 파일 업로드</h1>
        <h4 className='light'>파일은 인공지능의 분석 외에 활용되지 않고 즉시 삭제됩니다.</h4>
        <h3>단톡방도 좋고 개인 톡방도 좋습니다</h3>
        {/* 파일 선택 버튼 이미지화 | 경빈 | 22-07-02 */}
        <FileUploadStyle >
          <label htmlFor="ex_file">
            <div className="btnStart">
              <img src={process.env.PUBLIC_URL + '/image/button/파일선택.png'} alt="btnStart" />
            </div>
          </label>
          <input type="file" id="ex_file"
                 onChange={(e) => {
                  this.onFileChange(e);
                  // 동일한 파일 업로드 해도 분석가능하도록 구현 | 경빈 | 22-07-19
                  e.target.value = ''; 
                  }}/>
        </FileUploadStyle>

        {/* 파일 업로드 버튼 이미지화 | 경빈 | 22-07-04 */}
        {/* 분석 시작하기 버튼 삭제 | 경빈 | 22-07-19 */}
        {/* <button className='uploadFileBtn' onClick={() => { this.onFileUpload(!this.state.fileUploadBtnDisabled) }}>
          <img className='uploadFileImg' src={process.env.PUBLIC_URL + '/image/button/분석시작하기.png'} alt="uploadBtn" />
        </button> */}
        <h5 className='light'>광고 페이지 이동 후 분석이 시작됩니다</h5>

        <Adfit height={50} width={320} unit={"hTnetVlIpgUtPXFG"} scrFlag={true} position={"middle"}></Adfit>


        {/* <div className="adfit"></div> */}


        {this.fileData()}

        <Container>
          <h3>1. 폰에서 카톡 대화내용 가져오는 방법</h3>
          <StyledSlider {...settings}
          >
            {items.map(item => {
              return (
                <div key={item.id}>
                  <ImageContainer>
                    <Image src={item.url} />
                  </ImageContainer>
                </div>
              );
            })}
          </StyledSlider>
        </Container>

        <Container>
          <h3>2. PC에서 카톡 대화내용 가져오는 방법</h3>
          <StyledSlider {...settings}
          >
            {webitems.map(item => {
              return (
                <div key={item.id}>
                  <ImageContainer>
                    <Image src={item.url} />
                  </ImageContainer>
                </div>
              );
            })}
          </StyledSlider>
        </Container>
        <br/>
        <Link to="/qanda">
          <img className='img_3'
            src={`${process.env.PUBLIC_URL}/image/button/자주묻는질문.png`}
            alt="qAndaBtn"
          />
        </Link>
        <h5 className="light">"이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다."</h5>
        <Footer></Footer>
        <br/>
        <Adfit height={100} width={320} unit={"a3z80YxaOerWU9V9"} scrFlag={false} position={"bottom"}></Adfit>
        <iframe src="https://ads-partners.coupang.com/widgets.html?id=712312&template=carousel&trackingCode=AF6214453&subId=&width=320&height=100&tsource=" width="230" height="60" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
        <br />

      </div >
    )
  }
}

function withNavigation(Component) {
  return props => <Component {...props} navigate={useNavigate()} />;
}

export default withNavigation(Uploadfile);