import { useNavigate } from 'react-router-dom';
import React, {useEffect} from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';

const containerStyle = {
  backgroundImage: "url(/image/background/basic_background.jpg)",
}

const Content1 = () => (
  <p style={{ padding: '18px' }}>
    A1 : 계획은 있었습니다! 아이폰의 경우 대화 내용 내보내기를 하려면, 이메일이나 icloud 연동 방법뿐이라 지금보다 더 번거로운 방식의 운영이 불가피했습니다. PC에서 이용 부탁드려요,,
  </p>
);

const Content2 = () => (
  <p style={{ padding: '18px' }}>
    A2 : 현재 카톡분석은 형태소 단위의 분석을 진행하며, 심도있게 문장 의미까지 분석하지는 않기때문에 재미로 봐주시면 좋을것 같습니다. 문의사항이나 아이디어는 cs.advancer@gmail.com 으로 주시면 감사하겠습니다 :) !!
  </p>
);

const Content3 = () => (
  <p style={{ padding: '18px' }}>
    A3 : 죄송합니다..! 평범한 분석 페이지나 심리테스트 페이지의 경우, 선택하면 정해진 값을 출력하는 정적인 페이지이지만, 저희 페이지의 경우 카톡을 업로드하여 개인별로 분석을 진행하기 때문에 서버비가 더 들게됩니다.. 보다 재밌는 페이지를 제공하기 위한 수단임을 이해해주시면 감사하겠습니다 ㅠ ㅡㅠ,,
  </p>
);

const Content4 = () => (
  <p style={{ padding: '18px' }}>
    A4 : 네! 확실합니다! 저희는 AWS의 서버리스 플랫폼인 Lambda를 이용하며 일회성 분석 외 데이터를 따로 저장하거나 활용하지 않습니다 :)
  </p>
);

const Content5 = () => (
  <p style={{ padding: '18px' }}>
    A5 : 안드로이드 버전이 10 이하이고 카톡이 잠금설정 되어있다면 페이지가 바로 연결되지 않습니다. 뒤로가기 후에 크롬 앱을 클릭하시면 페이지를 열 수 있습니다. 혹은 안드로이드 업데이트를 하시거나 웹으로 이용하시는걸 추천드립니다 :)
  </p>
);


function QandA(props) {
  const navigate = useNavigate();
  
  useEffect(()=>{
    
    let ins = document.createElement('ins');
    let scr = document.createElement('script');
    ins.className = 'kakao_ad_area';
    ins.style = "display:none; width:100%;";
    scr.async = 'true';
    scr.type = "text/javascript";
    scr.src = "//t1.daumcdn.net/kas/static/ba.min.js";
    ins.setAttribute('data-ad-width','320');
    ins.setAttribute('data-ad-height','100');
    ins.setAttribute('data-ad-unit','DAN-a3z80YxaOerWU9V9');
    document.querySelector('.adfit').appendChild(ins);
    document.querySelector('.adfit').appendChild(scr);
    // 카톡 플러스 친구 채팅창 연결 | 경빈 | 22-07-20
    const script = document.createElement('script');
    script.async = true;
    try{
      if (window.Kakao) {
        const kakao = window.Kakao;
        if (!kakao.isInitialized()) {
          // kakao.init(process.env.REACT_APP_KAKAO_TOKEN);
          kakao.init("ae7d846563094c0ab1dcf688440e85d8");
        }
      }
    

    window.Kakao.Channel.createChatButton({
      container: '#kakao-talk-channel-chat-button',
      channelPublicId: '_xozsAxj',
      title: 'consult',
      size: 'small',
      color: 'yellow',
      shape: 'pc',
      supportMultipleDensities: true,
    });
    document.body.appendChild(script);
    document.body.removeChild(script);
  } catch (err){this.errorCatch(err)}
  }, [])

  return (
    <div className='container' style={containerStyle}>
      <img className='uploadFileImg' src={process.env.PUBLIC_URL + '/image/button/이전으로가기.png'} alt="goBackBtn" onClick={() => navigate(-1)} />
      <h1>Q&A</h1>
      <h2>왕에게 여쭙습니다</h2>
      <br />
      <Accordion atomic={true}>

      <AccordionItem title="Q1 : 아이폰은 왜 서비스가 안되나요?!">
        <Content1 />
      </AccordionItem>
      <br/>
      <AccordionItem title="Q2 : 제가 ~왕으로 나왔는데, 분석결과가 안 맞는 것 같아요">
        <Content2 />
      </AccordionItem>
      <br/>
      <AccordionItem title="Q3 : 광고가 보기 싫어요!">
        <Content3 />
      </AccordionItem>
      <br/>
      <AccordionItem title="Q4 : 데이터가 저장되지 않는 것이 확실한가요?">
        <Content4 />
      </AccordionItem>
      <br/>
      <AccordionItem title="Q5 : 전달받은 링크를 클릭했는데 페이지로 연결되지 않아요!">
        <Content5 />
      </AccordionItem>

    </Accordion>
    <h4>추가 문의사항은 아래의 카톡 채널로 연락 주시길 바랍니다.</h4>
    <div id="kakao-talk-channel-chat-button"></div>
    <br/>
    {/* <a href=''>
      <img src={`/image/button/instagramBtn.png`} 
            style = {{width: '15%'}}
            className="insta-btn" alt='insta-btn'></img>
    </a> */}
    <div className="adfit"></div>
    </div>
  );
}

export default QandA;

// https://github.com/farbodsalimi/react-light-accordion