import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import './App.css';
import { useLocation } from 'react-router'
import Footer from './footer';
import Adfit from "./Adfit";
import TestRRSimg from './image/rrs-title2.png';
import TestNYRimg from './image/nyr-title.png';

// export default function Totalresultchild(props){
const Totalresultchild = () => {

      const { state } = useLocation();
      const result = state.data;
      const talkNum = result.talk_num;   // 카톡 개수
      const memberNum = result.member_num; // 카톡방 구성원 수
      console.log(`${talkNum}, ${memberNum}`)
      const citizens = result.unselected_members; // 백성 목록
      const rankerNum = result.ranker_num;
      let keywordSentence = ""
      //-------반복문을 활용한 결괏값 렌더링 | 경빈 | 22-06-30-------//
      const getResult = () => {
            try {

                  let personalResult = [];
                  // 멤버별 결괏값

                  for (let i = 1; i <= rankerNum; i++) {
                        let ranker = 'rank_' + i.toString();
                        // 멤버의 상위 키워드별 결괏값
                        keywordSentence = ""
                        for (let j = 0; j < result[ranker].keyword.length; j++) {
                              keywordSentence += '"';
                              let term = result[ranker].keyword[j.toString()]['0']
                              keywordSentence += term;
                              keywordSentence += '" ';
                              let termNum = result[ranker].keyword[j.toString()]['1']
                              keywordSentence += termNum;
                              keywordSentence += "회 ";
                        }
                        keywordSentence += "언급하여";

                        let king_first = result[ranker].king_first;

                        let image = process.env.PUBLIC_URL + 'image/emoticon_800_800/' + king_first + '.png';
                        personalResult.push(
                              <div className='totalstyle' key={ranker} >
                                    <br />
                                    <h2>{result[ranker].member} 님은</h2>
                                    <img style={{ width: '35%' }} src={image} alt="king_photo" />
                                    <h4>{keywordSentence}</h4>
                                    <h3>전생에 {result[ranker].king_first}이었습니다!</h3>
                                    <h4 className='light'>전체 사용자 중 {result[ranker].king_first} 순위는 상위 {result[ranker].order_ratio}%입니다!</h4>
                                    <h4 className='light'>아쉽게 놓친 왕: {result[ranker].king_second}</h4>
                                    <Link to={"/indirect/" + i.toString()} state={{
                                          rankerNumber: i,
                                          data: result,
                                          keywordSentence: keywordSentence
                                    }}>
                                          <img className='img_3'
                                                src={`${process.env.PUBLIC_URL}/image/button/결과확인하기.png`}
                                                alt="start_btn" />
                                    </Link>
                                    <br /><br /><br /><br />
                              </div>
                        )
                  }
                  return personalResult;
            } catch (err) {
                  this.errorCatch(err);
            }
      }

      const visualizeCitizen = () => {
            if (0 < citizens.length) {
                  return (
                        <div>
                              <h2>백성 목록</h2>
                              {/* 백성 목록 구현 | 경빈 | 22-07-04 */}
                              <div>
                                    {citizens.map((citizen) => <li key={citizen}>{citizen}</li>)}
                              </div>
                        </div>
                  );
            }
            console.log("백성 미존재");
      }

      //--------------------------------------------------------//
      // 배경추가 | 경빈 | 22-07-01
      const containerStyle = {
            backgroundImage: "url(/image/background/basic_background.jpg)",
      }
      return (
            <div className='stretch_container' style={containerStyle}>
                  <Adfit height={50} width={320} unit={"hTnetVlIpgUtPXFG"} scrFlag={true} position={"middle"}></Adfit>

                  <br />
                  <h2>총 {memberNum}명이 참여한 </h2>
                  <h2> {talkNum}개의 카톡을 분석한 결과입니다.</h2>
                  <h4 className='light'>버튼을 눌러 자세한 분석 결과를 확인해 보세요 :)</h4>
                  <br />
                  <div className="adfit"></div>

                  {getResult()}
                  {/* 백성 목록 구현 | 경빈 | 22-07-04 */}
                  {/* 백성 존재하는 경우만 '백성 목록' 구현 | 경빈 | 22-07-19 */}
                  {visualizeCitizen()}
                  <br /><br />
                  <Adfit height={100} width={320} unit={"a3z80YxaOerWU9V9"} scrFlag={false} position={"bottom"}></Adfit>
                  <br /><br />
                  <Link to="/">
                        <img className='img_3'
                              src={`${process.env.PUBLIC_URL}/image/button/다시하기.png`}
                              alt="retry"
                        />
                  </Link>
                  <Link to="/qanda">
                        <img className='img_3'
                              src={`${process.env.PUBLIC_URL}/image/button/자주묻는질문.png`}
                              alt="qAndaBtn"
                        />
                  </Link>
                  <div>
                        <br/>
                        <span>👇 다른 테스트 해보기 👇</span><br />
                        <br/>
                        <img src={TestRRSimg} width={230} height={60} onClick={() => { window.open('https://advancer-rrs.click/'); }} /><br />
                        <img src={TestNYRimg} width={230} height={60} onClick={() => { window.open('https://www.newyearrabbit.click/'); }} />
                  </div>
                  <br /><br />
                  <Footer></Footer>
                  <br />
            </div>
      );
};

export default Totalresultchild;