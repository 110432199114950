// 컴포넌트를 정의하는 프로그램으로 실제로 화면에 표시되는 내용들 정의
import './App.css';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import Mainpage from './Mainpage';
import Uploadfile from './Uploadfile';
import Analysis from './Analysis';
import Totalresult from './Totalresult';
import Indiresult from './Indiresult';
import QandA from './QandA';
import IndiresultShare from './indiresultShare';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Mainpage />} />
      <Route path="/uploadfile" element={<Uploadfile />} />
      <Route path="/analysis" element={<Analysis />} />
      <Route path="/totalresult" element={<Totalresult />} />
      <Route path="/indirect/:id" element={<Indiresult />} />
      <Route path="/share/:id" element={<IndiresultShare />} />
      <Route path="/qanda" element={<QandA />} />
      {/* URL 실수든 고의든 잘못 입력 시 무조건 메인 페이지로 이동 */}
      <Route path="/*" element={<Mainpage />} /> 
    </Routes>
  );
};

export default App;