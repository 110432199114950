import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GetUserIdAPI } from './parameter';
import CountUp from 'react-countup';
import Adfit from "./Adfit";
import styled from 'styled-components';


function Button({ children, ...rest }) {
  return <StyledButton {...rest}>{children}</StyledButton>;
}


const containerStyle = {
  backgroundImage: "url(/image/background/mainpage.jpg)",
}

const Mainpage = () => {

  const [userNum, setUserNum] = useState(0);

  // 총 분석한 사용자 수 가져오기 | 경빈 | 22-07-05
  const getUserNum = async () => {
    try {
      axios
        .get(GetUserIdAPI)
        .then(response => {
          console.log(`사용자 수: ${response.data}`);
          setUserNum(response.data);
        })
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  useEffect(() => {
    getUserNum();
  }, [])


  return (
    <div className='stretch_container' style={containerStyle}>
      <h4 className='right'>v1.0.7</h4>
      <div className='mtop'>
        <Button>
          현재까지 총 
          <span style={{ color: 'blue', fontSize: '1.5rem', fontWeight: 'bold'}}>
          <CountUp end={userNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} duration={1}/>
          </span>
          개 의 카톡방을 분석했습니다.
        </Button>
      </div>

      <br/><br />
      <Link to="/uploadfile">
        <img className='img_1'
          src={`${process.env.PUBLIC_URL}/image/button/시작하기.png`}
          alt="start_btn"
        />
      </Link>
      {/* <Adfit height={50} width={320} unit={"2cMwxntsRTznCLQq"} scrFlag={true} position={"middle"}></Adfit> */}
      <br/>
      {/* <Adfit height={100} width={320} unit={"ImwluAeuNIRwA7Cm"} scrFlag={false} position={"bottom"}></Adfit> */}
      <Adfit height={100} width={320} unit={"a3z80YxaOerWU9V9"} scrFlag={true} position={"bottom"}></Adfit>
      

    </div>
  );
};

export default Mainpage;

const StyledButton = styled.button`
  display: inline-flex;
  outline: none;
  border: none;
  border-radius: 10px;
  color: black;
  // font-weight: bold;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;

  /* 크기 */
  height: 1.5rem;
  font-size: 1.25rem;

  /* 색상 */
  background: rgba(255,255,102,0.5);
  // background: #FAFAB4;
  // &:hover {
  //   background: #339af0;
  // }
  // &:active {
  //   background: #1c7ed6;
  // }

  font-family: "misaeng";
`