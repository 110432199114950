import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GetResultAPI } from './parameter';
import Footer from './footer';

const TIMEOUT = 10000;
const TALK_MIN_NUM = 100; // 최소 카톡 개수

// 시간 지연
const DELAY_TIME = 3000;

//---------------파일 분석가능 여부에 따른 출력값----------------//
const FAIL_MSG = `카톡 수가 적어 대화 분석이 어렵습니다. 카톡 개수가 최소 ${TALK_MIN_NUM}개 이상인 카톡을 업로드해 주세요!`;
// const FAIL_BTN = "다시 업로드하기";
// const SUCCESS_MSG = "분석이 완료되었습니다. 아래 버튼을 클릭하여 결과를 확인해 주세요!";
// const SUCCESS_BTN = "결과 확인하기";
//-------------------------------------------------------//


const containerStyle = {
  backgroundImage: "url(/image/background/basic_background.jpg)",
}

// function component 형식으로 작성
export default function Analysis() {
  const { state } = useLocation();
  console.log("userID: ", state.userID)
  const [result, setResult] = useState({});
  const [delay, setDelay] = useState(0);

  useEffect(()=>{
    let ins = document.createElement('ins');
    let scr = document.createElement('script');
    ins.className = 'kakao_ad_area';
    ins.style = "display:none; width:100%;";
    scr.async = 'true';
    scr.type = "text/javascript";
    scr.src = "//t1.daumcdn.net/kas/static/ba.min.js";
    ins.setAttribute('data-ad-width','320');
    ins.setAttribute('data-ad-height','100');
    ins.setAttribute('data-ad-unit','DAN-a3z80YxaOerWU9V9');
    document.querySelector('.adfit').appendChild(ins);
    document.querySelector('.adfit').appendChild(scr);
  }, [])

  let timer;
  const getData = async () => {
    try {
      const params = { userID: state.userID };
      await axios
        .get(GetResultAPI, { params, timeout: TIMEOUT })
        .then((result) => {
          //-----시간 지연 구현 | 경빈 | 22-07-04-----//
          setResult(result.data)
          console.log(`result: ${result}`)
          console.log(`${DELAY_TIME}초간 지연 START`);
          timer = setTimeout(() => {
            console.log(`${DELAY_TIME}초간 지연 END`);
            setDelay(1);
            console.log(`result: ${result}`)
          }, DELAY_TIME);
          //-------------------------------------//
        });
    } catch (error) { console.log(`Error: ${error}`) }
  }

  useEffect(() => {
    getData();
    setDelay(0);
    return () => clearTimeout(timer);
  }, []); // 빈 객체 할당: 마운트되고 최초 1회 실행

  const checkValidation = result => {
    // 시간 지연 구현 | 경빈 | 22-07-04
    if (delay === 1) {
      // 카톡 개수가 특정 값 이하이거나 임명된 왕이 없는 경우 처리 | 경빈 | 22-07-01
      if (result.talk_num < TALK_MIN_NUM || !Object.keys(result).includes('rank_1')) {
        return (
          // 버튼 이미지화 | 경빈 | 22-07-04
          <div>
            <h5>{FAIL_MSG}</h5>
            <Link to="/uploadfile" state={{ data: result }}>
              <img className='img_3'
                src={`${process.env.PUBLIC_URL}/image/button/다시하기.png`}
                alt="start_btn" />
            </Link>

          </div>);
      } else {
        // console.log("분석 가능한 카톡방인 경우");
        return (
          <div>
            {openMyWindow()}

            <h2>분석 완료!</h2>
            <h3>아래 버튼을 클릭하여 결과를 확인해 주세요.</h3>


            <Link to="/totalresult" state={{ data: result }}>
              <img className='img_3'
                src={`${process.env.PUBLIC_URL}/image/button/결과확인하기.png`}
                alt="start_btn" />
            </Link>

          </div>

        );
      }
    }
    else {
      console.log("딜레이 전");
      return (
        // 분석 중 gif 삽입 | 경빈 | 22-07-04
        <div>
          <h3>키워드 분석을 통해 최대 10명의 왕을 선정합니다.</h3>
          <img style={{ width: '70%' }} src={'/image/background/loading_new.gif'} alt="loading" /><br />
          <h3>최대 10초 소요됩니다.</h3>
        </div>
      )
    }

  }

  const openMyWindow = () => {
    window.open("https://link.coupang.com/a/8S7fY", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0")
    // width=580, height=600, top=30
   }

  return (

    <div className='container' style={containerStyle}>

      <br />
      {checkValidation(result)}
      <br />
      <Link to="/qanda">
        <img className='img_3'
          src={`${process.env.PUBLIC_URL}/image/button/자주묻는질문.png`}
          alt="qAndaBtn"
        />
      </Link>
      <br/><br/><br/>
      <div className="adfit"></div>
      <br/>

      <h5 className='light'>파일은 1회성 분석 외에 사용되지 않고 삭제됩니다.</h5>
      <h5 className='light'>이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다.</h5>

      <Footer></Footer>
    </div>
  );

}