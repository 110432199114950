// HTML 템플릿 및 JavaScript의 컴포넌트를 조합하여 렌더링하고 실제 표현한다.

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

window.Kakao.init("ae7d846563094c0ab1dcf688440e85d8");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);