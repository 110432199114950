import React from 'react';

const Footer = () => {
    return (
        <div>
            <h5>ⓒ 2023. UF5 all rights reserved. v1.0.7</h5>
            <h5>📬 Contact: gbpark@uf5.io</h5>
        </div>
    );
}

export default Footer;