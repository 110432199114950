import { Link, useNavigate } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useEffect, useState } from 'react';
import './components/style.css';
// import Popup from './components/Popup'
import { useLocation, useParams } from 'react-router'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'; //Legend
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import Swal from "sweetalert2";
import Footer from './footer';
import Adfit from "./Adfit";
import styled from 'styled-components';
import GiftUrlCSV from './gift_url.csv';
import Papa from 'papaparse';
import { usePapaParse } from 'react-papaparse';
import TestRRSimg from './image/rrs-title2.png';
import TestKTAimg from './image/kta-title.png';
import TestNYRimg from './image/nyr-title.png';
import TestMZimg from './image/mz-title.PNG';

const StyledButton = styled.button`
  display: inline-block;
  outline: none;
  border: none;
  border-radius: 30px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding-left: 3rem;
  padding-right: 3rem;

  /* 크기 */
  // height: 10rem;
  font-size: 1rem;

  /* 색상 */
  background: rgba(153,102,102,0.2);

  font-family: "misaeng";
`

function Button({ children, ...rest }) {
  return <StyledButton {...rest}>{children}</StyledButton>;
}

// const resultID = window.location.href.split('/')[4];

//---개별 분석 시각화 | 경빈 | 22-06-30---/
// 코드 정렬 | 경빈 | 22-07-05 //
const Indiresult = () => {
  const { state } = useLocation();
  const result = state.data;            // DB 데이터
  const rankerNumber = state.rankerNumber;    // ranker 순위
  const keywordSentence = state.keywordSentence; // 빈출 어휘
  const ranker = 'rank_' + rankerNumber.toString(); // 인덱싱 목적
  const king_first = result[ranker].king_first;  // 왕
  const king_first_score = result[ranker].score; // 왕 점수 추가 | 경빈 | 22-07-05
  const usefulGift = result[ranker].gift_useful; // 유용한 선물 리스트
  const funnyGift = result[ranker].gift_funny;  // 재미난 선물 리스트
  const king_seocnd = result[ranker].king_second; // 아쉽게 놓친 왕
  const member = result[ranker].member;      // 멤버 이름
  const order_ratio = result[ranker].order_ratio; // 왕 상위 랭킹
  const king_total_rank = result.king_total_num;      // 전체 왕 분포(1등~5등)
  const emoticon_first = process.env.PUBLIC_URL + '/image/emoticon_800_800/' + king_first + '.png';
  const emoticon_second = process.env.PUBLIC_URL + '/image/emoticon_800_800/' + king_seocnd + '.png';
  const token = result[ranker].token;
  let kingGiftUrlTotal = {}; // 모든 왕에 대한 선물, URL 저장
  // let usefulGiftURL = [];
  // let funnyGiftURL = [];

  let link = "";
  let cp_gift_link = "";

  let katalkLink = "https://www.advancer-katalk.click/share/" + token; // 배포용
  // let katalkLink = "http://localhost:3000/share/" + token; // 로컬 테스트용  

  // 서비스 전체 사용자 데이터 기반 왕 랭킹(1등: ... , 2등: ...)
  const king_total_rank_prep = [];
  for (let i = 0; i < king_total_rank.length; i++) {
    const king_rank_data = {
      name: king_total_rank[i.toString()]['0'],
      비율: king_total_rank[i.toString()]['1']
    }
    king_total_rank_prep.push(king_rank_data);
  };
  const VisualizeKingTotalRank = (
    // 차트 위치 가운데 맞추기 | 경빈 | 22-07-05
    <ResponsiveContainer width='90%' aspect={5.0 / 3.0}>
      <BarChart
        data={king_total_rank_prep}
        layout="vertical"
        margin={{
          left: 30,
          bottom: 10,
        }}
        barSize={20}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" domain={[0, 25]} />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey="비율" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>

  )

  console.log(`king_total_rank_prep: ${king_total_rank_prep}`);
  console.log("1등: ", king_total_rank['0']['0']);
  console.log(keywordSentence)
  console.log("ranker 번호: ", rankerNumber);
  console.log("result: ", result);
  // const currentUrl = window.location.href;
  const [buttonPopup, setButtonPopup] = useState(false);
  const [usefulGiftURL, setUsefulGiftURL] = useState([]);
  const [funnyGiftURL, setFunnyGiftURL] = useState([]);
  const navigate = useNavigate();

  const containerStyle = {
    backgroundImage: "url(/image/background/basic_background.jpg)",
    // width : "auto%",
    // height : "100%"
  }

  // const url = window.location.href; //현재 url가져오기
  useEffect(() => {
    try {
      ReadGiftUrlCSV(); // 선물&링크 csv 파일 읽고 변수에 데이터 할당
      initKakao(); // 카카오 계정 초기화
    } catch (err) {
      this.errorCatch(err);
    }
  }, []);

  const initKakao = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        // kakao.init(process.env.REACT_APP_KAKAO_TOKEN);
        kakao.init("62ced804222bfd2579649e12d4679972");
      }
    }
  };

  //버튼을 누르면 실행되는 함수
  const shareKakao = (king_first, e) => {
    console.log(king_first);
    if (king_first === '자발적아싸왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/AImaL8W8G2.png"
    }
    else if (king_first === '다한다습왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/3SerTihOD8.png"
    }
    else if (king_first === '이모티콘왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/ft5zf5mH7B.png"
    }
    else if (king_first === '카페인왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/eAXUnCIttk.png"
    }
    else if (king_first === '리액션왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/MVKlBTDVxU.png"
    }
    else if (king_first === '카메라왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/FwqDdqdEO0.png"
    }
    else if (king_first === '욕쟁이왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/sDMpqm9xVt.png"
    }
    else if (king_first === '부정왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/VYix30tiid.png"
    }
    else if (king_first === '삭제왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/D4bT1b37HI.png"
    }
    else if (king_first === '안부왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/xa2kSY0NEg.png"
    }
    else if (king_first === '근육왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/jgTCZvOG8Q.png"
    }
    else if (king_first === '식탐왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/8yGomITst5.png"
    }
    else if (king_first === '웃음왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/Pd85AOZUxp.png"
    }
    else if (king_first === '뭐해왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/Jj0Rc7jDOE.png"
    }
    else if (king_first === '노래왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/w1fiphF5Wn.png"
    }
    else if (king_first === '게임왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/Y7CAw4AhjX.png"
    }
    else if (king_first === '피곤왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/clt90Ba6IM.png"
    }
    else if (king_first === '눈물왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/1NP4RS7MEw.png"
    }
    else if (king_first === '음주왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/momQYpqF87.png"
    }
    else if (king_first === '수다왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/qFehllvruA.png"
    }
    else if (king_first === '사랑왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/TP5KJzxTyj.png"
    }
    else if (king_first === '긍정왕') {
      link = "https://i.esdrop.com/d/f/1snazidKCy/BrW7bbLCPT.png"
    }

    // emoticon = 'https://i.esdrop.com/d/f/1snazidKCy/AImaL8W8G2.png',
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title:
          `
${member}님은 "${king_first}"
"${usefulGift[0].split('.')[0]}" 선물을 추천해요
`,
        description:
          `
#나는 전생에 어떤 왕..?
#카톡을 웹에서 분석해보세요
`,
        imageUrl: link,

        link: {
          mobileWebUrl: katalkLink,
          webUrl: katalkLink,
        },
      },
      social: {
        likeCount: 286,
        commentCount: 45,
        sharedCount: 845,
      },
      buttons: [
        {
          title: '웹으로 보기',
          link: {
            mobileWebUrl: katalkLink,
            webUrl: katalkLink,
            // webUrl : "http://www.advancer-katalk.click/indiresultShare/" + token,
          },
        },
      ],
    });

  };

  // dom-to-image
  // font rendering 때문에 저장속도 문제있음
  // font 없이 빠르게하려면 dom-to-image-improved 사용
  // 운영적 절충 위해 swal 사용 
  var options = {
    quality: 0.5
  };
  const onDownloadBtn = () => {
    console.log("savesavesavesaveasve");
    domtoimage
      .toBlob(document.querySelector('.capture'), { bgcolor: 'white' }, options)
      .then((blob) => {
        saveAs(blob, 'king_choice.png');
      })
  };

  // 저작권 관련 팝업 | 경빈 
  const imageCopyRight = () => {
    const imageCopyRightMsg =
      "저작권 이슈 발생 시 하기 이메일로 연락 주시길 바랍니다. \
      <br> \
      <br> 감사합니다. \
      <br> \
      <br> 📬 고객가치혁신팀 / cs.advancer@gmail.com"
    Swal.fire({
      icon: 'info',
      title: '상품 이미지 저작권',
      type: 'info',
      html: imageCopyRightMsg,
      confirmButtonText: '알겠습니다',
    });
  }

  // CSV 파일 처리 | 경빈 | 22-07-27
  const FetchCSV = () => {
    return fetch(GiftUrlCSV).then(function (response) {
      let reader = response.body.getReader();
      let decoder = new TextDecoder('utf-8');
      return reader.read().then(function (result) {
        return decoder.decode(result.value);
      });
    });
  }

  // 선물별 링크 가져오기 | 경빈 | 22-07-27
  const getGiftURL = () => {
    const usefulGiftURLlist = [];
    const funnyGiftURLlist = [];
    console.log("rrrrrrrrrrrrrrrrrrrrr");
    console.log(kingGiftUrlTotal);
    console.log(kingGiftUrlTotal[king_first]);
    console.log(king_first);
    kingGiftUrlTotal[king_first]["실용"].map((data) => {
      if (usefulGiftURLlist.length < 2) {
        if (data.gift_name === usefulGift[0].split('.')[0] || data.gift_name === usefulGift[1].split('.')[0]) {
          usefulGiftURLlist.push(data.gift_url);
        }
      }
    });
    kingGiftUrlTotal[king_first]["재미"].map((data) => {
      if (data.gift_name === funnyGift[0].split('.')[0]) {
        funnyGiftURLlist.push(data.gift_url);
      }
    });
    // console.log(`usefulGiftURL: ${usefulGiftURL[0]}`);
    // console.log(`usefulGiftURL: ${usefulGiftURL[1]}`);
    // console.log(`funnyGiftURL: ${funnyGiftURL[0]}`);
    setUsefulGiftURL(usefulGiftURLlist);
    setFunnyGiftURL(funnyGiftURLlist);
  };

  // 쿠팡 선물-URL CSV 파일 읽기 | 경빈 | 22-07-27
  const ReadGiftUrlCSV = async () => {
    let csvData = await FetchCSV();
    let results = Papa.parse(csvData, { header: true });
    let kingGiftUrlObj = {}; // 왕 1명에 대한 선물, URL 저장
    let usefulGiftList = []; // 실용 선물 리스트 저장
    let funnyGiftList = []; // 재미 선물 리스트 저장
    let kingLast = "게임왕";
    results.data.map((rows) => {
      const kingName = rows.king_name;
      const giftType = rows.gift_type;
      const giftName = rows.gift_name;
      const giftURL = rows.url;

      // 새로운 왕 탐색하는 경우
      if (kingName != kingLast) {
        kingGiftUrlObj["실용"] = usefulGiftList;
        kingGiftUrlObj["재미"] = funnyGiftList;
        usefulGiftList = funnyGiftList = []; // 객체 초기화
        kingGiftUrlTotal[kingLast] = kingGiftUrlObj;
        kingGiftUrlObj = {} // 객체 초기화
        kingLast = kingName // 새로 탐색하는 왕
      }

      let params = {
        "gift_name": giftName,
        "gift_url": giftURL,
      }
      // 실용-재미 선물 구분
      giftType === '실용' ? usefulGiftList.push(params) : funnyGiftList.push(params);

    });
    getGiftURL();
  };

  return (
    <div className='stretch_container' style={containerStyle}>
      <Adfit height={50} width={320} unit={"hTnetVlIpgUtPXFG"} scrFlag={true} position={"middle"}></Adfit>
      <br />
      <img className='uploadFileImg' src={process.env.PUBLIC_URL + '/image/button/이전으로가기.png'} alt="uploadBtn" onClick={() => navigate(-1)} />
      <br />
      <div className="capture" >
        {/* <h1>임명장</h1> */}
        <br /><br />
        <img src={`${process.env.PUBLIC_URL}/image/background/임명장2.png`} className='img_2' alt='img'></img>
        <br />
        <img style={{ width: '45%' }} src={emoticon_first} alt="king_photo" /><br />
        <Button>
          <h2><span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{member}</span> 님은</h2>
          {/* <h2><u>{member}</u> 님은</h2> */}
          <h3 className='light'>{keywordSentence}</h3>
          {/* <u>{keywordSentence}</u> */}
          <h3 className='light'>대화방 내 <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{king_first}</span> 으로 선포합니다!</h3>
          {/* 대화방 내 <u>{king_first}</u>을 담당하고 있음을 선포합니다! */}
        </Button>
        <br /><br />
        <h5 className='dashLine'></h5>
        {/* 선물 이미지 크기 조정 | 경빈 | 22-07-04 */}
        <h3><span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{king_first}</span> 이 좋아할 만한 선물은?</h3>
        {/* <h3><u>{king_first}</u>이 좋아할 만한 선물은?</h3><br /> */}
        {/* 왕별 선물 매칭 및 랜덤화 구현 | 경빈 | 22-07-18 */}
        <br />
        <div className='gift-parent'>
          <div className='gift-child' >
            <a href={usefulGiftURL[0]} target="_blank">
              <img src={`/image/coupang_gift/${king_first}/실용/${usefulGift[0]}`} className="gift-child-img" alt='present1'></img>
            </a>
            <h4 className='light'>{usefulGift[0].split('.')[0]}</h4>

            <a href={usefulGiftURL[0]} target="_blank">
              <img src={`${process.env.PUBLIC_URL}/image/button/선물하기.png`} className="img_5" alt='present1'></img>
            </a>

          </div>

          <div className='gift-child'>
            <a href={usefulGiftURL[1]} target="_blank">
              <img src={`/image/coupang_gift/${king_first}/실용/${usefulGift[1]}`} className="gift-child-img" alt='present2'></img>
            </a>
            <h4 className='light'>{usefulGift[1].split('.')[0]}</h4>
            <a href={usefulGiftURL[1]} target="_blank">
              <img src={`${process.env.PUBLIC_URL}/image/button/선물하기.png`} className="img_5" alt='present2'></img>
            </a>
          </div>

          <div className='gift-child'>
            <a href={funnyGiftURL[0]} target="_blank">
              <img src={`/image/coupang_gift/${king_first}/재미/${funnyGift[0]}`} className="gift-child-img" alt='present3'></img>
            </a>
            <h4 className='light'>{funnyGift[0].split('.')[0]}</h4>
            <a href={funnyGiftURL[0]} target="_blank">
              <img src={`${process.env.PUBLIC_URL}/image/button/선물하기.png`} className="img_5" alt='present3'></img>
            </a>
          </div>
        </div>
        <br /><br />
        {/* <span style={{ float: 'right', fontSize: 'small', alignContent: 'right', textAlign: 'right', color: '#000000', marginRight: '20px', fontWeight: 'bold' }}>이미지 클릭 시 선물하기 페이지로 이동합니다.</span>
        <br /> */}
        <span style={{ float: 'right', fontSize: 'small', alignContent: 'right', textAlign: 'right', color: '#0066ff', marginRight: '20px' }} onClick={() => imageCopyRight()}><u>*이미지 저작권 공지</u></span>
        <br />
        <h5 className='dashLine'></h5>

        <div className='gift-parent'>
          <div className='gift-child'>
            <h3>당신이 아쉽게 놓친 왕은?</h3>
            {/* 이미지 크기 늘리기 | 경빈 | 22-07-04 */}
            <img style={{ width: '60%' }} src={emoticon_second} alt="king_photo" /><br />
            <h4 className='light'><span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{king_seocnd}</span> 입니다!</h4>
            {/* <h4 className='light'><u>{king_seocnd}</u>입니다!</h4> */}
          </div>
          <div className='gift-child'>
            <h3>당신의 <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{king_first}</span> 역량은?</h3>
            {/* <h3>당신의 <u>{king_first}</u> 역량은?</h3> */}

            {/* 글자수 안맞아서 전세계 일단 밑으로 빼둠 | 윤수 | 22-07-03*/}
            {/* 텍스트 수직 방향 가운데로 정렬 | 경빈 | 22-07-04 */}
            {/* 맞춤법 교정 | 경빈 | 22-07-05 */}

            <h4 style={{ color: "red", fontSize: 18, marginTop: 90 }}>전 세계 상위 <span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{order_ratio}</span>%</h4>
            {/* <h4 style={{ color: "red", fontSize: 18, marginTop: 90 }}>전 세계 상위 <u>{order_ratio}%</u></h4> */}
            <h4 className='light'>(역량점수: <span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{king_first_score}</span> 점)</h4>
            {/* <h4 className='light'>(역량점수: <u>{king_first_score}점</u>)</h4> 왕 키워드 사용횟수 총합 */}

          </div>
          <br />
        </div>
        <h5 className='dashLine'></h5>
        <div>
          <h3>전 세계 가장 많은 왕 순위는?</h3>
          {VisualizeKingTotalRank}
          <br /><br />
        </div>

        {/* capture range */}
      </div>

      <Adfit height={100} width={320} unit={"a3z80YxaOerWU9V9"} scrFlag={false} position={"bottom"}></Adfit>
      <br /><br /><br />

      <div className="share-node">
        <CopyToClipboard text={katalkLink}>
          <img className='img_4'
            // src = {myImage}
            src={`${process.env.PUBLIC_URL}/image/button/결과링크복사.png`}
            onClick={() => {
              setButtonPopup(true)
              Swal.fire({
                icon: "success",
                title: '링크 복사 완료!',
                type: "success",
                confirmButtonText: '확인',
                confirmButtonColor: '#3085d6',
              })
            }}
            alt="linkCopyBtn"
          />
        </CopyToClipboard>
        <img className='img_4'
          src={`${process.env.PUBLIC_URL}/image/button/카카오톡 공유.png`}
          alt="카카오톡공유"
          onClick={(e) => { shareKakao(king_first, e) }} />
      </div>

      <img className='img_3'
        src={`${process.env.PUBLIC_URL}/image/button/이미지저장.png`}
        alt="이미지저장"
        onClick={() => {
          onDownloadBtn()
          Swal.fire({
            icon: "success",
            title: '저장을 시작합니다',
            text: '다운로드 폴더에 저장됩니다',
            type: "success",
            confirmButtonText: '확인',
            confirmButtonColor: '#3085d6',
          })
        }} />

      <Link to="/">
        <img className='img_3'
          src={`${process.env.PUBLIC_URL}/image/button/처음으로가기.png`}
          alt="mainPageBtn"
        />
      </Link>

      <Link to="/qanda">
        <img className='img_3'
          src={`${process.env.PUBLIC_URL}/image/button/자주묻는질문.png`}
          alt="QandABtn"
        />
      </Link>
      <div>
        <br />
        <span>👇 다른 테스트 해보기 👇</span><br />
        <br />
        <img src={TestRRSimg} width={230} height={60} onClick={() => { window.open('https://advancer-rrs.click/'); }} /><br />
        <img src={TestNYRimg} width={230} height={60} onClick={() => { window.open('https://newyearrabbit.click/'); }} />
        <img src={TestMZimg} width={230} height={60} onClick={() => { window.open('https://mzofficetest.click/'); }} />
      </div>
      <br/>
      <h5 className='light'>이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다.</h5>
      <Footer></Footer>

      <br />
      <br />
    </div>
  );
};

export default Indiresult;